import * as React from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { onError } from "@apollo/link-error";

import { config } from "../config";
import { NeedVerification } from "./need_verification";
import { useLocation, useNavigate } from "react-router-dom";
import { UserNotAllowedAuth } from "./user_not_allowed_auth";

const httpLink = createHttpLink({
  uri: config.API_HOST,
  fetchOptions: {
    mode: "cors",
  },
});

const errorLink = onError(({ networkError, operation, forward }) => {
  if (
    networkError.message === "Login required" ||
    networkError.message === "Consent required" ||
    networkError.message === "Unknown or invalid refresh token."
  ) {
    operation.setContext({ needLogin: true });
    return forward(operation);
  }
});

export const CustomApolloProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const location = useLocation();
  const navigate = useNavigate();

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, operation) => {
      if (operation.needLogin === true) {
        await loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
        });
      }

      const token = await getAccessTokenSilently();

      return {
        headers: {
          ...operation.headers,
          authorization: token ? `Bearer ${token}` : "",
        },
        needLogin: false,
      };
    });

    return new ApolloClient({
      link: ApolloLink.from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }, [loginWithRedirect, getAccessTokenSilently]);

  React.useEffect(() => {
    if (error) {
      if (location.search !== "") {
        navigate(window.location.pathname);
      }
    }
  });

  if (error) {
    console.log(error.message);

    if (error.message === "Mail verification required") {
      return <NeedVerification />;
    }

    if (error.message === "User did not authorize the request") {
      return <UserNotAllowedAuth />;
    }

    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <div>Now loading</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
    return <></>;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
