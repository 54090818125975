import * as React from "react";
import { Routes, Route } from "react-router-dom";

import { Home } from "./home";
import { New } from "./new";

export const App = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/new" element={<New />} />
    </Routes>
  );
};
