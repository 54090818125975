import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AddChildResult = {
  __typename?: "AddChildResult";
  success: Scalars["Boolean"]["output"];
};

export type Child = {
  __typename?: "Child";
  name: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  addChild: AddChildResult;
};

export type MutationAddChildArgs = {
  name: Scalars["String"]["input"];
};

export type Query = {
  __typename?: "Query";
  children: Array<Child>;
};

export type GetChildrenQueryVariables = Exact<{ [key: string]: never }>;

export type GetChildrenQuery = {
  __typename?: "Query";
  children: Array<{ __typename?: "Child"; name: string }>;
};

export type AddChildMutationVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type AddChildMutation = {
  __typename?: "Mutation";
  addChild: { __typename?: "AddChildResult"; success: boolean };
};

export const GetChildrenDocument = gql`
  query GetChildren {
    children {
      name
    }
  }
`;

/**
 * __useGetChildrenQuery__
 *
 * To run a query within a React component, call `useGetChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildrenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChildrenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChildrenQuery,
    GetChildrenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChildrenQuery, GetChildrenQueryVariables>(
    GetChildrenDocument,
    options,
  );
}
export function useGetChildrenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChildrenQuery,
    GetChildrenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChildrenQuery, GetChildrenQueryVariables>(
    GetChildrenDocument,
    options,
  );
}
export type GetChildrenQueryHookResult = ReturnType<typeof useGetChildrenQuery>;
export type GetChildrenLazyQueryHookResult = ReturnType<
  typeof useGetChildrenLazyQuery
>;
export type GetChildrenQueryResult = Apollo.QueryResult<
  GetChildrenQuery,
  GetChildrenQueryVariables
>;
export const AddChildDocument = gql`
  mutation AddChild($name: String!) {
    addChild(name: $name) {
      success
    }
  }
`;
export type AddChildMutationFn = Apollo.MutationFunction<
  AddChildMutation,
  AddChildMutationVariables
>;

/**
 * __useAddChildMutation__
 *
 * To run a mutation, you first call `useAddChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChildMutation, { data, loading, error }] = useAddChildMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddChildMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddChildMutation,
    AddChildMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddChildMutation, AddChildMutationVariables>(
    AddChildDocument,
    options,
  );
}
export type AddChildMutationHookResult = ReturnType<typeof useAddChildMutation>;
export type AddChildMutationResult = Apollo.MutationResult<AddChildMutation>;
export type AddChildMutationOptions = Apollo.BaseMutationOptions<
  AddChildMutation,
  AddChildMutationVariables
>;
