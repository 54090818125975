import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { config } from "../config";
import { CustomApolloProvider } from "./apollo";
import { App } from "./app";

const Main = (): React.ReactElement => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname,
    );
  };

  const providerConfig = {
    domain: config.AUTH0_DOMAIN,
    clientId: config.AUTH0_CLIENT_ID,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: config.API_HOST,
    },
    useRefreshTokens: true,
  };

  return (
    <Auth0Provider {...providerConfig}>
      <CustomApolloProvider>
        <App />
      </CustomApolloProvider>
    </Auth0Provider>
  );
};

const root = createRoot(document.getElementById("main"));
root.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>,
);
