import * as React from "react";
import { Link } from "react-router-dom";

import { useGetChildrenQuery } from "./generated/graphql";
import { useAuth0 } from "@auth0/auth0-react";

export const Home = (): React.ReactElement => {
  const { logout } = useAuth0();

  const { loading, error, data } = useGetChildrenQuery({
    fetchPolicy: "network-only",
  });

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  if (loading) {
    return <div>now loading</div>;
  }

  if (error) {
    return <div>error {error.message}</div>;
  }

  const children = data.children.map((c) => c.name).join(", ");

  return (
    <>
      <div>
        <Link to="/new">New</Link>
      </div>
      <div>
        <button onClick={handleLogout}>Logout</button>
      </div>
      <div>{children}</div>
    </>
  );
};
