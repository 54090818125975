import * as React from "react";
import { Link } from "react-router-dom";

import { useAddChildMutation } from "./generated/graphql";
import { useAuth0 } from "@auth0/auth0-react";

export const New = (): React.ReactElement => {
  const [name, setName] = React.useState("");
  const [created, setCreated] = React.useState(false);

  const { loginWithRedirect } = useAuth0();

  const [addChildMutation] = useAddChildMutation({
    onCompleted() {
      setCreated(true);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addChildMutation({
      variables: {
        name,
      },
    });
    await loginWithRedirect({
      // どうしても token を更新しないといけない場合(子供を追加した直後くらいしか思いつかない)は強制的に redirect させて token を取得し直す
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };

  return (
    <>
      <div>
        <Link to="/">Home</Link>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <input type="submit" value="New" />
        </form>
      </div>
      {created ? <div>Created!</div> : <></>}
    </>
  );
};
