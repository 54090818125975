import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const UserNotAllowedAuth = (): React.ReactElement => {
  const { logout } = useAuth0();

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <>
      <div>ユーザーが認証を拒否した場合</div>
      <div>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </>
  );
};
