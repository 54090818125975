import { ConfigType } from "./type";
import { devConf } from "./development";
import { prodConf } from "./production";
import { env } from "./env";

const __MODE__ = env("BUILD_MODE");

let conf: ConfigType = devConf;
if (__MODE__ === "production") {
  conf = prodConf;
}

export const config = conf;
